module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Effeqtiv","short_name":"Effeqtiv","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0368e1b2fac5fb9684ebb37bf1d1ed37"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en"],"defaultLanguage":"en","generateDefaultLanguagePage":false,"siteUrl":"https://www.effeqtiv.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"defaultNS":"common"},"pages":[{"matchPath":"/:lang?/blog","getLanguageFromPath":false},{"matchPath":"/:lang?/faq","getLanguageFromPath":false},{"matchPath":"/","getLanguageFromPath":true},{"matchPath":"/:lang?/","getLanguageFromPath":true},{"matchPath":"/:lang?/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true}],"useSuspense":true,"redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal-with-new-react/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@spencerwhyte/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-KECQTYJNJG","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
